import React from "react";
import './css/App.css';
import './index.css';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import Welcome from "./Components/Welcome";
import Login from "./Components/Login";
import Verify from "./Components/Verify";
import Header from "./Components/Header";
import Body from "./Components/Body";
import GetResult from "./Components/GetResult";
import BaseUrlContext from "./Components/BaseUrlContext";

// const Welcome = lazy(() => import('./Components/Welcome'));
// const Login = lazy(() => import('./Components/Login'));
// const Verify = lazy(() => import('./Components/Verify'));
// const Header = lazy(() => import('./Components/Header'));
// const Body = lazy(() => import('./Components/Body'));
// const GetResult = lazy(() => import('./Components/GetResult'));
/* <Suspense fallback={<Loading />}>
  <AnyComponent />
</Suspense> */
function App() {
  return (
    <Router>
      <Switch>

      <BaseUrlContext.Provider value="https://kpanel.yoniks.com/QuizApp/Api">
          <Route exact path="/">
            <div className="main_div_before">
              <Welcome />
            </div>
          </Route>

            <Route exact path="/login">
              <div className="main_div_before">
                <Login />
                <Verify />
              </div>
            </Route>

            <Route exact path="/tests">
              <Header time='true' SessionValue='Verification' />
              <Body />
            </Route>

            <Route exact path="/result">
              <Header time='false' SessionValue='user_name' />
              <GetResult />
            </Route>
      </BaseUrlContext.Provider>

        <Route>
          <Redirect to='/'></Redirect>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
