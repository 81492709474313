import React , { useState , useEffect , useContext } from 'react'
import Loading from './Loading';
import axios from 'axios';
import BaseUrlContext from './BaseUrlContext';

function Courses(){
    const baseUrl = useContext(BaseUrlContext);
    
    const [CoursesData, setCoursesData] = useState([]);
    const [QuesLevel, setQuesLevel] = useState('level')
    const [CourseId, setCourseId] = useState('')
    const [QuesCount, setQuesCount] = useState(0)
    useEffect(() => {
        let url = `${baseUrl}/fetch_courses.php?key=iteyoniks_QuizApi_2022`;
        // Fetch List of Courses
        axios.get(url)
        .then( res => {
            let data1 = res.data;
            setCoursesData(data1);
        })
        .catch(err=>{
            console.log(err);
        })
    }, [baseUrl])

    const FetchQuesCount = (id,level) => {
       
        // Fetch Count of Questions Related to Selected Course 
       if(level=='level'){
        let url5 = `${baseUrl}/allCount.php?course_id=${id}`;
        axios.get(url5)
        .then( res => {
            console.log(res);
          let count = res.data[0].QCount;
          setQuesCount(count)
          console.log(count)
        })
        .catch(err=>{
          console.log(err);
        })
       }
       else{
        let url5 = `${baseUrl}/fetch_courses.php?course_id=${id}&level=${level}`;
        axios.get(url5)
        .then( res => {
            console.log(res);

          let count = res.data[0].QCount;
          setQuesCount(count)
          console.log(count)
        })
        .catch(err=>{
          console.log(err);
        })
       }

       
    }
    const ChangeLevel = (e) =>{
        let ques_level = e.target.value;
        setQuesLevel(ques_level);
        FetchQuesCount(CourseId,ques_level);
    }
    const ChangeCourse = (e) =>{
        var Cid = e.target.value;
        setCourseId(Cid)
        FetchQuesCount(Cid,QuesLevel);
    }
    return (
        <div>
            <label htmlFor="">Choose Course</label>
            { CoursesData ? 
                <>
                <select name="user_cid" id="course" className='form-select' onChange={ChangeCourse}>
                    <option value=''>Select Course/Skill/Test</option>
                    {CoursesData.map(Courses => {
                        return(
                        <option key={Courses.cid} 
                                value={Courses.cid}>
                            {Courses.cname}
                        </option>
                        )
                    })}
                </select>
                <span id="course_select_error" className='error'></span> 
                </>
                : <Loading />
            }
                
            <label htmlFor="">Questions Level</label>
            <select name='user_ques_level' className='form-control' onChange={ChangeLevel} >
                  <option value='level'>Level of Questions</option>
                  <option value='beginner'>Beginner</option>
                  <option value='learner'>Learner</option>
                  <option value='expert'>Expert</option>
                  <option value='master'>Master</option>
                </select>
            <span id="ques_level_error" className='error'></span>
            
            <div className='row'>
                <div className='col-lg-4 col-md-4 col-sm-12'>
                    Available Questions 
                    { QuesCount 
                      ? <input className='form-control' name='available_ques' 
                              value={QuesCount} key={QuesCount} readOnly />  
                      : <input className='form-control' name='available_ques' readOnly />
                    }
                </div>
                  
                <div className='col-lg-8 col-md-8 col-sm-12'>
                  <label htmlFor="">Count Of Questions</label>
                  <input type="number" name="user_q_count" className='form-control' />
                  <span id="ques_count_error" className='error'></span> 
                </div>
            </div>
        </div>
    )
  }

export default React.memo(Courses)
