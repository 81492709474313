import React , { useState , useEffect } from 'react'

function Timer() {
    const [timer, setTimer] = useState(0)

  useEffect(() => {
    const interval = () => setInterval(()=>{setTimer(prevTimer=>prevTimer+1)},1000)
    interval();
    return () => {
      clearInterval();
    }
  }, [])
  return (
    <React.Fragment>
      {timer ? timer : '00:00:00'}
    </React.Fragment>
  )
}

export default Timer
