import React from 'react'
import logo from '../images/iteyoniks_logo.png';

function IteYoniksLogo(props) {
  const { GivenClass , width , height } = props;
  return (
    <img src={logo} alt="IteYoinksLogo" 
      className={GivenClass ? GivenClass : null}
      width={width} height={height} /> 
  )
}

export default IteYoniksLogo
