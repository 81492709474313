import React from 'react'

const Loading = () => {
  return (
    <div>
        <p className='text-center'> 
            <i className="fa fa-circle-o-notch fa-spin h4 mt-3"></i> Loading...
        </p>
    </div>
  )
}

export default React.memo(Loading)
