import React from 'react';
import '../css/header.css'
import { useHistory } from "react-router-dom";
import IteYoniksLogo from './IteyoniksLogo';
import Timer from './Timer';

export default function Header(props) {
  const { SessionValue , time } = props;
  var Verification = sessionStorage.getItem(SessionValue);
  const history = useHistory();
  if(Verification === null || Verification === undefined){
    history.push('/');
  }

  var user_name = sessionStorage.getItem('user_name');
  return (

    <nav className="navbar navbar-expand-md navbar-dark shadow">
      <div className="container-fluid">
          <IteYoniksLogo GivenClass="app-logo" width='180px' height='60px' />
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
              <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse " id="mynavbar">
              <ul className="navbar-nav">
                <li className="nav-item h4">
                    <span className="nav-link text-light">
                      Name : {user_name}
                    </span>
                </li>
                { time === 'true'
                  ?<li className='nav-item h4 ' id='show'>
                        <span className="nav-link text-light">
                          Timer : <span id='end_time'><Timer /></span>
                        </span>
                    </li>
                  :null
                }
              </ul>
          </div>
      </div>
    </nav>
  )
}

