import React from 'react';
import '../css/welcome.css';
import { Link } from 'react-router-dom';
import IteYoinksLogo from './IteyoniksLogo';
import bulb from '../images/bulb3.png';

export default function Welcome(){
    return (    
        <div className='welcome_page'>
            <div className='d-flex justify-content-center'>
                <IteYoinksLogo width='250px' height='90px' />
            </div>
            <div className='d-flex justify-content-center my-2'>
                <img src={bulb} alt="Bulb/Hint" className='bulb_img' />
            </div>
            <h1 className='text-center mb-2'>
                Welcome to Test Hub
                <pre className='subh' >By ITE Yoniks Services </pre>
                Challenge Your Knowledge.
            </h1>
            <Link className='all_btn form-control py-1 btn_hover' to='/login' >
                <h4>
                    Start
                </h4>    
            </Link>
        </div>
    )
}
