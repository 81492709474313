import React, { useState , useEffect , useContext} from 'react';
import axios from 'axios';
import Loading from './Loading';
import BaseUrlContext from './BaseUrlContext';
import { useHistory } from 'react-router-dom';


function GetResult(){

    const baseUrl = useContext(BaseUrlContext);
    
    const [Result, setResult] = useState(false);
    const [QuestionsData, setQuestionsData] = useState([]);

    useEffect(() => {
      const test_id = sessionStorage.getItem('user_test_id');
      const Email = sessionStorage.getItem('user_email');
      
      let Data = {
        test_id: test_id, 
        email: Email
      };
      const sendData = JSON.stringify(Data);

      // Api Call for Fetching Result with respect to TestId and Email
      axios.post(`${baseUrl}/get_result.php`, sendData)
      .then( res => {
        let TotalPoints = res.data.result
        setResult(TotalPoints)
      })
      .catch(err=>console.log(err))
      
      // Api Call for Fetching Questions with respect to TestId
      let url2 = `${baseUrl}/fetch_questions.php?test_id=${test_id}`;
      axios.get(url2)
      .then( res => {
        setQuestionsData(res.data);
      })
      .catch(err=>console.log(err))
    }, [baseUrl]);
    
    let history = useHistory();
    const Play_Again = () =>{
      sessionStorage.clear();
      history.push('/');
    }

    const TotalQuesCount = sessionStorage.getItem('user_q_count');
    var EndingTime = sessionStorage.getItem('end_time');
    var Count = 1;

    return (
      <>
        <div className="result_div shadow-lg">
          <div id="show_result d-flex justify-content-center">
            {
              Result ? <div>
                {
                  <div key={Result} className='d-flex justify-content-start p-1'>
                    <div className='ms-3 pb-3'>
                      <h1>Congratulations</h1>
                      <p className='ms-1'>
                        <span className='text-warning'> 
                          Total Questions : {TotalQuesCount}
                        </span> <br />
                        <span className='text-success'> 
                          Correct Questions : {Result}
                        </span> <br />
                        <span className='text-danger'> 
                          Wrong Questions : {TotalQuesCount-Result}
                        </span> <br />
                        <span> 
                          Time : { EndingTime < 60 
                                   ? EndingTime + ' seconds'
                                   : Math.floor(EndingTime/60) + ' minutes'
                          } 
                        </span> <br />
                      </p>
                      <button className='all_btn btn_hover px-2' onClick={Play_Again}>
                        Play Again
                      </button>
                    </div>
                    
                  </div>
                  
                }
              </div>
                : <Loading />
            }
          </div>
        </div>

        <div className='mb-3'>
        {
          QuestionsData ?
          QuestionsData.map(Question => {
            
            const ShowResult =()=>{
              if(Question.user_ans === 'A'){
                return Question.A
              }
              else if(Question.user_ans === 'B'){
                return Question.B
              }
              else if(Question.user_ans === 'C'){
                return Question.C
              }
              else if(Question.user_ans === 'D'){
                return Question.D
              }
              else{
                return 'You have Not Attempt the Question'
              }
            }

            return (
              <>
                <div key={Question.qid}>
                  <div>
                    <h5 className="question m-1">
                      {Count++}. {Question.question}
                    </h5>
                  </div>
                  <div className='user_options_div'>
                    <div className='w-100'>
                      <div className='user_choose w-100'  
                           id={Question.right_ans === 'A' ? 'right_ans':null}> 
                        <span className='border-end-0'></span> 
                        <span>A</span> 
                        <span>{Question.A}</span>
                      </div> 
                      <hr />
                      <div className='user_choose w-100' 
                           id={Question.right_ans === 'B' ? 'right_ans':null}> 
                        <span className='border-end-0'></span> 
                        <span>B</span> 
                        <span>{Question.B}</span>
                      </div> 
                      <hr />
                      <div className='user_choose w-100' 
                           id={Question.right_ans === 'C' ? 'right_ans':null}> 
                        <span className='border-end-0'></span> 
                        <span>C</span> 
                        <span>{Question.C}</span>
                      </div> 
                      <hr />
                      <div className='user_choose w-100' 
                           id={Question.right_ans === 'D' ? 'right_ans':null}> 
                        <span className='border-end-0'></span> 
                        <span>D</span> 
                        <span>{Question.D}</span>
                      </div> 
                    </div>
                  </div>
                  <div className='p-3 m-1' 
                       id={Question.user_ans === Question.right_ans ? 'right_ans':'wrong_ans'}>
                    Your Ans   {Question.user_ans === '0' ? '' : Question.user_ans} {ShowResult()}
                  </div>
                </div>
              </>
            )
          })
          : <Loading />
        }
        </div>
      </>
    )
  }

export default GetResult