import React , { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Loading from './Loading';
import BaseUrlContext from './BaseUrlContext';

export default function Body(){
    const baseUrl = useContext(BaseUrlContext);
  
    const [loading, setLoading] = useState(false);
    const [QuestionsData, setQuestionsData] = useState('');
    const courseId = sessionStorage.getItem('user_cid');
    const ques_level = sessionStorage.getItem('user_ques_level');
    const question_count = sessionStorage.getItem('user_q_count');
    useEffect(() => {
      let sendData = JSON.stringify({
        CourseId: courseId,
        Ques_level: ques_level,
        QuestionCount: question_count
      })
      axios.post(`${baseUrl}/fetch_questions.php`, sendData)
      .then( res => {
          setQuestionsData(res.data);
      })
      .catch( err => {
          console.log(err);
      });
    }, [courseId,ques_level,question_count,baseUrl]);

    let history = useHistory();

    const FinalScore = sessionStorage.getItem('user_q_count');
    const INSERT_TEST = async (e) => {
      e.preventDefault();
      document.getElementById('show').style.display = 'none';
      var EndTime = document.getElementById('end_time').innerHTML;
      sessionStorage.setItem('end_time', EndTime);
      document.getElementById('SubmitButton').style.display = "none";
      
      setLoading(true);

      for (var x = 0; x < FinalScore; x++) {
        var test_id = sessionStorage.getItem('user_test_id');
        var question_id = document.getElementsByClassName('questionId')[x].value;
        var correct_ans = document.getElementsByClassName('correct_ans')[x].value;
        var user_ans = document.getElementsByClassName('user_ans')[x].value;

        let point;
        if (correct_ans === user_ans) {
          point = 1;
        }
        else {
          point = 0;
        }
        console.log(`QuesId = ${question_id}  user ans = ${user_ans}`);

        // Converting data to JSON Form
        let sendData = JSON.stringify({ 
          test_id: test_id, 
          question_id: question_id, 
          correct_ans: correct_ans, 
          user_ans: user_ans, 
          point: point
        });
        // Insert Test Record to Tests Table
        const res = await axios.post(`${baseUrl}/insert_tests.php`, sendData)
        .then( res => {
          return res
        })
        .catch( err => {
          return err
        });
        console.log(res.data);
      }
      sessionStorage.removeItem('Verification');
      history.push('/result');
    }
    const ChangeColor = (e) => {
      e.target.style.backgroundColor = 'rgb(20 175 40)';
    }
    var Count = 1;

    return (
      <div>
        {
          QuestionsData ?
            <div>
              <>
                <div className="main_div_app">
                  <div className="questions_div">
                  <form >
                        {
                          QuestionsData.map(Question => {
                            return (
                              <>
                                <div key={Question.qid}>
                                  <div>
                                    <h5 className="question mt-1">
                                      {Count++}. {Question.question}
                                    </h5>
                                  </div>
                                  <div className='user_options_div'>
                                    <div >
                                      <div className="user_choose "> 
                                        <span className='border-end-0'></span> 
                                        <span>A</span> 
                                        <span>{Question.A}</span>
                                      </div> 
                                      <hr />
                                      <div className="user_choose "> 
                                        <span className='border-end-0'></span> 
                                        <span>B</span> 
                                        <span>{Question.B}</span>
                                      </div> 
                                      <hr />
                                      <div className="user_choose "> 
                                        <span className='border-end-0'></span> 
                                        <span>C</span> 
                                        <span>{Question.C}</span>
                                      </div> 
                                      <hr />
                                      <div className="user_choose"> 
                                        <span className='border-end-0'></span> 
                                        <span>D</span> 
                                        <span>{Question.D}</span>
                                      </div> 
                                      <hr />
                                    </div>
                                    <div >
                                      <label className='green_btn w-100 py-1 ps-1'>Choose</label>
                                      <select className='user_ans w-100 py-1' onChange={ChangeColor}>
                                        <option value="0">Ans</option>
                                        <option value='A' >A</option>
                                        <option value='B' >B</option>
                                        <option value='C' >C</option>
                                        <option value='D' >D</option>
                                      </select>
                                    </div>
                                  </div>

                                  <input type="text" hidden value={Question.qid} className="questionId" readOnly />
                                  <input type="text" hidden value={Question.ans} className="correct_ans" readOnly />

                                </div>
                              </>
                            )
                          })
                        }

                      <button type='submit' 
                              className="all_btn btn_hover" 
                              onClick={INSERT_TEST} 
                              id="SubmitButton">
                                Submit
                      </button>
                    </form>
                  </div>
              </div>
                      {loading ? <Loading /> : null}
              </>
            </div>
            : <Loading />
        }
      </div>
    )
  
}
