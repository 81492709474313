import React from "react";
import { useHistory } from "react-router-dom";

export default function Verify() {
    const history = useHistory();

    const Verification = (e) => {
      e.preventDefault();
      var user_verify_val = document.getElementsByName('user_verify')[0].value;
      var user_verify = user_verify_val.trim();
      var verification_val = sessionStorage.getItem('user_verify');
      if(user_verify === verification_val){
        sessionStorage.setItem('Verification', 'true');
        history.push('/tests');
      }
      else{
        var error = document.getElementById("verify_error");
        error.innerHTML = "Verification Code Doesn't Match";
      }
    }

    return (
        <>
        <div className="verification_div shadow-lg"> 
            <h3>Enter Verification Code</h3>
            <form id="verification_form">
                <label htmlFor="">Verification Code</label>
                <input type="text" name='user_verify' className="form-control" placeholder="Verification Code" />
                <span id="verify_error" className="error"></span>
                <button type="submit" onClick={Verification} className="all_btn mt-2 btn_hover" >
                    Start
                </button>
            </form>
        </div>
      </>
    )
  }