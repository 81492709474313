import React , { useContext , useState } from 'react';
import axios from 'axios';
import '../css/login.css';
import validator from 'validator';
import Loading from './Loading';
import Courses from './Courses';
import IteYoniksLogo from './IteyoniksLogo';
import BaseUrlContext from './BaseUrlContext';

function Login(){
    const baseUrl = useContext(BaseUrlContext)
    const [loading , setLoading] = useState(false);
    const SendMail = async (e) => {
        e.preventDefault();
        var name = document.getElementsByName('user_name')[0].value;
        var email = document.getElementsByName('user_email')[0].value;
        var ques_level = document.getElementsByName('user_ques_level')[0].value;
        var course_id = document.getElementsByName('user_cid')[0].value;
        var ques_count = +document.getElementsByName('user_q_count')[0].value;
        var available_ques = +document.getElementsByName('available_ques')[0].value;

        if(name === ""){
          document.getElementById('name_error').innerHTML = "Fill the Name Field <br />";
          return false;
        }
        else{
          document.getElementById('name_error').innerHTML = "";
        }

        if(email === ""){
          document.getElementById('email_error').innerHTML = 'Fill the E-mail Field <br />';
          return false;
        }
        else if(validator.isEmail(email) === false) {
          document.getElementById('email_error').innerHTML = 'Follow the E-mail Pattern <br />';
          return false;
        }
        else{
          document.getElementById('email_error').innerHTML = '';   
        }

        if(ques_level === ""){
          document.getElementById('ques_level_error').innerHTML = 'Select the Level of Questions <br />';
          return false;
        }
        else{
          document.getElementById('ques_level_error').innerHTML = '';   
        }

        if(course_id === ""){
          document.getElementById('course_select_error').innerHTML = "Select Course first";
          return false;
        }
        else{
          document.getElementById('course_select_error').innerHTML = "";
        }

        if(ques_count === "" || ques_count === 0){
          document.getElementById('ques_count_error').innerHTML = "Fill the Count of Questions";
          return false;
        }
        if( ques_count < 0 || ques_count > available_ques ){
          document.getElementById('ques_count_error').innerHTML = "Fill the Questions between 1 to "+available_ques;
          return false;
        }
        else{
          document.getElementById('ques_count_error').innerHTML = "";
        }
        
        document.getElementById('next_btn').setAttribute('disabled', true);
        var verify_value = Math.floor(Math.random() * 1000000);
        var test_id = verify_value +'_'+ name.split(/\s+/).join('');

        console.log(ques_count+'  '+available_ques);
        sessionStorage.setItem('user_name', name);
        sessionStorage.setItem('user_email', email);
        sessionStorage.setItem('user_ques_level', ques_level);
        sessionStorage.setItem('user_cid', course_id);
        sessionStorage.setItem('user_q_count', ques_count);
        sessionStorage.setItem('user_test_id', test_id);
        sessionStorage.setItem('user_verify', verify_value);

        setLoading(true);

        let Data = {
          name:name, 
          email:email, 
          course_id:course_id, 
          ques_count:ques_count, 
          verify:verify_value, 
          test_id:test_id
        };
        let sendData = JSON.stringify(Data); // Data in JSON From

        // Api Call For Inserting User Data to Database
        let response = await axios.post(`${baseUrl}/insert_user.php`, sendData)
        .then( res => {
          return res;
        })
        .catch( err => {
          console.log(err);
          return err
        });
        if(response.data.status === 200){
            alert('Verification Code is Sent on Your Email');
            setLoading(false);
        }
        document.getElementsByClassName('setup_quiz_div')[0].style.display = 'none';
        document.getElementsByClassName('verification_div')[0].style.display = 'block';
    }

  return(
      <>
        <div className="setup_quiz_div shadow-lg">
            <div className='d-flex my-0 justify-content-around'>
              <h3>
                Setup Your Test With Test Hub
              </h3>
              <IteYoniksLogo width="100px" height="40px"/>
            </div>
            <div className="setup_quiz_form">
              <form id='login_form'>
                <label htmlFor="">Name</label>
                <input type="text" placeholder="Name" name="user_name" className="form-control" />
                <span id="name_error" className='error'></span> 
                <label htmlFor="">E-mail</label>
                <input type="email" placeholder="E-mail" name="user_email" className="form-control" />
                <span id="email_error" className='error'></span>

                {/* Courses Component Imported Which Has Other Inputs */}
                <Courses  />

                <button type="submit" className="all_btn btn_hover" id="next_btn" onClick={SendMail}>
                    Next
                </button>
              </form>
            </div>
        </div>
        {loading?<Loading />:null}
      </>
  )
}
export default Login